import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter)

const routes = [

  {
    path: '/:one/:two/:three/:id',
    name: 'RatioV',
    component: () => import(/* webpackChunkName: "about" */ '@/components/RatioV.vue')
  },
  {
    path: '/',
    alias: '/home',
    name: 'AccueilV',
    component: () => import(/* webpackChunkName: "about" */ '@/components/AccueilV.vue')
  },

 

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router